export const portalData = {
  // Fax Details
  hasFax: false,
  faxNo: "",
  
  // Whatsapp details
  hasWhatsApp: true,
  whatsAppNo: "+(609)6902 902",
  whatsAppNoHolderName: "Tuna Bay",
  hasAltWhatsApp: false,
  altWhatsAppNo: "",
  altWhatsAppNoHolderName: "",
  hasAltWhatsApp1: false,
  altWhatsAppNo1: "",
  altWhatsAppNoHolderName1: "",
  hasAltWhatsApp2: false,
  altWhatsAppNo2: "",
  altWhatsAppNoHolderName2: false,
  
  // Phone number details
  hasPhoneNo: true,
  phoneNo: true,
  hasAltPhoneNo: true,
  altPhoneNo: true,
  
  // Telephone number details
  hasTellNo: true,
  tellNo: "+(609) 6902 902",
  tellNoHolderName: "",
  hasAltTellNo: false,
  altTellNo: "",
  altTellNoHolderName: "",
  hasAltTellNo2: false,
  altTellNo2: "",
  altTellNoHolderName2: "",
  
  // Portal information details
  portalName: "Tuna Bay",
  portalFullName: "Tuna Bay Island Resort",
  portalShortAddress: "Terengganu Darul Iman, Malaysia.",
  portalAddress: "No. 120, Jalan Besar, 22300 Kuala Besut, Terengganu Darul Iman, Malaysia.",
  portalAddressFirstPart: "No. 120, Jalan Besar, 22300 Kuala Besut,",
  portalAddressSecondPart: "Terengganu Darul Iman, Malaysia.",
  portalAddressDetails: "No. 120, Jalan Besar, 22300 Kuala Besut, Terengganu Darul Iman, Malaysia.",
  hasPortalRegNo: false,
  portalRegNo: "",
  
  // Portal Bank Details
  portalBankName: "MAYBANK BERHAD",
  portalBankAddress: "LOT 405 & 406, JALAN BESAR 22000, JERTIH TERENGGANU, MALAYSIA",
  portalBankAccHolderName: "TUNA BAY RESORTS",
  portalBankAccNo: "563046108351",
  hasPortalBankSwiftCode: true,
  portalBankSwiftCode: "MBBEMYKL",
   
  portalEmail: "survivor@tunabay.com.my",
  portalTermAndCondUrl: process.env.REACT_APP_PORTAL_TERM_AND_COND_URL,
   
  // GST details
  hasGst: false,
  gstNo: true,
   
  // Website
  portalWebsite: process.env.REACT_APP_PORTAL_WEBSITE,
 
  // Travoxis Website
  travoxisWebsite: process.env.REACT_APP_TRAVOXIS_WEBSITE,
 
  // Facebook page link
  facebookMessengerLink: "https://web.facebook.com/TunaBayIslandResort",
 
  // portal S3 base url
  s3AwsUrl: "",
 
  adminPortalUrl: process.env.REACT_APP_ADMIN_PORTAL_URL,

  // Portal Jetty names
  jettyOne: "Kuala Besut",
  jettyTwo: "Tuna Bay Island Resort",
  jettyThree: "",
  jettyOneToTwoTime: "10:30 AM",
  jettyTwoToOneTime: "02:00 PM"
}